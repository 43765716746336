// 全局过滤器
/*      
function trim(str)                      删除左边空格 
function parseTime(str,cFormat)         时间格式化
function formatNuber(val)               保留小数2位
function formatMoney(val)               格式货币
*/
export function trim(str) {
  return str.replace(/(^\s*)|(\s*$)/g, '');
}


export function parseTime(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}

export function formatNuber(val) {
  let valNumber = Number(val)
  return valNumber == 0 ? '0.00' : valNumber.toFixed(2)
}

export function formatMoney(s, n) {
  if (s) {
    n = n > 0 && n <= 20 ? n : 2
    s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + ''
    let l = s
      .split('.')[0]
      .split('')
      .reverse()
    let r = s.split('.')[1]
    let t = ''
    let len = l.length
    for (let i = 0; i < len; i++) {
      t += l[i] + ((i + 1) % 3 == 0 && i + 1 != l.length ? ',' : '')
    }
    return (
      t
      .split('')
      .reverse()
      .join('') +
      '.' +
      r
    )
  } else {
    return '0.00'
  }
}

export function modulePosition(key) {
  let label = '';
  switch (key) {
    case 1:
      label = '首页';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function moduleStyle(key) {
  let label = '';
  switch (key) {
    case 101:
      label = '单栏推荐';
      break;
    case 102:
      label = '双栏推荐';
      break;
    case 201:
      label = '单列商品';
      break;
    case 202:
      label = '双列商品';
      break;
    case 203:
      label = '横向滑动';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function linkType(key) {
  let label = '';
  switch (key) {
    case 1:
      label = '商品详情页';
      break;
    case 2:
      label = '精品单选页';
      break;
    case 3:
      label = '链接页';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function avertisementType(key) {
  let label = '';
  switch (key) {
    case 1:
      label = '单栏';
      break;
    case 2:
      label = '双栏';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function positionType(key) {
  let label = '';
  switch (key) {
    case 1:
      label = '首页';
      break;
    case 2:
      label = '猜你喜欢';
      break;
    case 3:
      label = '搜索页';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function menuType(key) {
  let label = '';
  switch (key) {
    case 1:
      label = '固定样式';
      break;
    case 2:
      label = '商品样式';
      break;
    case 3:
      label = '分类样式';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function userType(key) {
  let label = '';
  switch (key) {
    case 1:
      label = '渠道会员';
      break;
    case 2:
      label = '普通会员';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function userRegisterStepType(key) {
  let label = '';
  switch (key) {
    case 0:
      label = '未注册';
      break;
    case 1:
      label = '第三方授权成功';
      break;
    case 2:
      label = '绑定手机号成功';
      break;
    case 3:
      label = '使用邀请码注册成功';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function userSourceType(key) {
  let label = '';
  switch (key) {
    case 1:
      label = '手机号注册';
      break;
    case 2:
      label = '微信';
      break;
    case 3:
      label = '淘宝';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function platformStatusType(key) {
  let label = '';
  switch (key) {
    case 3:
      label = '已结算';
      break;
    case 12:
      label = '已付款';
      break;
    case 13:
      label = '已关闭';
      break;
    case 14:
      label = '已收货';
      break;
    case 18:
      label = '已返佣';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function orderRoleType(key) {
  let label = '';
  switch (key) {
    case 2:
      label = '二方';
      break;
    case 3:
      label = '三方';
      break;
    default:
      label = '-';
      break;
  }
  return label
}
export function refundStatusType(key) {
  let label = '';
  switch (key) {
    case 4:
      label = '维权创建(淘客结算回执)';
      break;
    case 2:
      label = '维权成功(淘客结算回执)';
      break;
    case 3:
      label = '维权失败(淘客结算回执)';
      break;
    case 11:
      label = '发生多次维权，待处理';
      break;
    case 12:
      label = '从淘客处补扣（钱已结给淘客） 等待扣款';
      break;
    case 13:
      label = '从淘客处补扣（钱已结给淘客） 扣款成功';
      break;
    case 14:
      label = '从卖家处补扣（钱已结给卖家） 等待扣款';
      break;
    case 15:
      label = '从卖家处补扣（钱已结给卖家） 扣款成功';
      break;
    default:
      label = '-';
      break;
  }
  return label
}

export * from "./auth-status-filter"