/**
 * 权限认证指令
 *
 */

export const auth = {
  inserted(el, binding, vnode) {
    const data = vnode.context.$store.getters["accountStore/getActionKeyList"];
    if (data.indexOf(binding.value) == -1) {
      const _parentElement = el.parentNode;
      if (_parentElement) {
        _parentElement.removeChild(el);
      }
    }
  }
}
