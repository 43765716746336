import Vue from 'vue'
import VueRouter from 'vue-router'
import home from './home'

// let head = document.getElementsByTagName('head');
// let meta = document.createElement('meta');
// meta.name = 'referrer';
// //根据实际情况修改referrer的值，可选值参考上文
// meta.content = 'no-referrer-when-downgrade';
// head[0].appendChild(meta);

Vue.use(VueRouter)

const routes = [

  ...home,
  // ...developer,
  // ...application,
  // ...game,
  // {
  //   path: '/access',
  //   name: 'access',
  //   component: () => import('@/views/AccessIndex.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'access',
  //       component: () => import('@/components/access.vue')
  //     },
  //   ]
  // },
  // {
  //   path: '*',
  //   name: 'error',
  //   component: () => import('@/views/Index.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'error',
  //       component: () => import('@/components/Error.vue')
  //     },
  //   ],
  // },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    // return 期望滚动到哪个的位置
    return { x: 0, y: 0 }
  }
})
// router.beforeEach((to, from, next) => {
//   // to: Route: 即将要进入的目标 路由对象
//   // from: Route: 当前导航正要离开的路由
//   // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。
//   const Token = localStorage.getItem('Token'); // 登录token
//   if (to.name != 'login' && to.name != 'error') {//非登录页 非错误页 
//     if (!Token) {//无token
//       localStorage.clear();
//       // 先执行清除本地缓存，再跳转登录页
//       setTimeout(function () {
//         next({ name: 'login' })//跳转登录页
//       }, 10);
//       return
//     }
//   }
//   next();
// });
export default router
