let apiBase = {

    preReview: '/api/game/preReview',
    preReviewList: '/api/game/getPreReviewPage',

    gameList: '/api/game/getGamePage',                 // 列表
    gameVersionList: '/api/game/getVersionPage',                 // 列表
    gameVersionDetail: '/api/game/getVersionDetail',                 // 详情
    export: '/api/game/export',                // 导出

}
let singleSeverObject = null
class GameServer {
    constructor() { }

    preReview(data = {}) {
        return $http({
            url: apiBase.preReview,
            method: 'post',
            data: data,
        })
    }

    preReviewList(data = {}) {
        return $http({
            url: apiBase.preReviewList,
            method: 'post',
            data: data,
        })
    }



    gameList(data = {}) {
        return $http({
            url: apiBase.gameList,
            method: 'post',
            data: data,
        })
    }

    gameVersionList(data = {}) {
        return $http({
            url: apiBase.gameVersionList,
            method: 'post',
            data: data,
        })
    }

    gameVersionDetail(data = {}) {
        return $http({
            method: 'post',
            url: apiBase.gameVersionDetail,
            data: data
        })
    }

    export(data={}){
        return $http({
          url: apiBase.export,
          method: 'post',
          data: data,
        })
      }
}
function singleSever() {
    if (singleSeverObject === null) {
        singleSeverObject = new GameServer()
    }
    return singleSeverObject
}
export default singleSever()