let apiBase = {
  uploadImage: '/api/file/uploadImg',     // 文件上传          
  
}
let fileSever = null
class MemberSever {
  constructor() { }

  uploadImage(data = {}) {
    return $http({
      url: apiBase.uploadImage,
      method: 'post',
      data: data,
    })
  }
}
function singleSever() {
  if (fileSever === null) {
    fileSever = new MemberSever()
  }
  return fileSever
}
export default singleSever()