
const utils = {
  getQueryString: function (name) {//截取参数
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    var r = window.location.search.substr(1).match(reg)
    if (r != null) return r[2]
    return ''
  },
  formatNumber: function (n) {//时间补0
    n = n.toString()
    return n[1] ? n : '0' + n
  },
  isEmpty: function (obj) { //是否为空
    if (typeof obj == "undefined" || !obj && typeof obj != "undefined" && obj != 0) {
      return false;
    }
    let arr = Object.keys(obj);
    if (arr.length) {
      return true;
    } else {
      return false;
    }
  },
  deepClone: function (obj) { //深拷贝
    return JSON.parse(JSON.stringify(obj));
  },
  throttle: function (fn, delay, atleast) { //节流
    var timer = null;
    var previous = null;
    return function () {
      var now = +new Date();
      if (!previous) previous = now;
      if (atleast && now - previous > atleast) {
        fn(this);
        previous = now;
        clearTimeout(timer);
      } else {
        clearTimeout(timer);
        timer = setTimeout(function () {
          fn(this);
          previous = null;
        }, delay);
      }
    }
  },
  parseTime: function (time, cFormat) {//时间格式化

    if (arguments.length === 0) {
      return null
    }
    const format = cFormat || '{y}/{m}/{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  },
  deepFlatten: function (arr) {//扁平化数组
    return [].concat(...arr.map(v => (Array.isArray(v) ? this.deepFlatten(v) : v)));
  },
  getArrFromObj: function (obj, arr) {//json 过滤 
    const rtn = []
    const iter = obj => {
      Object.keys(obj).forEach(item => {
        arr.includes(item) && rtn.push(obj[item].authorizedRoutes)
        utils.isEmpty(obj[item].child) && iter(obj[item].child)
      });
    }
    iter(obj)
    return this.deepFlatten(rtn)
  },

  getDevelopType(data) {
    switch (data) {
      case 1:
        return '企业'
      case 2:
        return '个人'
      case 3:
        return '政府/事业单位'
    }
  },
  isSize(file, width, height) {

    const isSize = new Promise(function (resolve, reject) {
      let _URL = window.URL || window.webkitURL;
      let img = new Image();
      img.onload = function () {
        let valid = img.width == width && img.height == height;
        if (!valid) {
          valid = img.width == width && height == 0;
        }
        if (!valid) {
          valid = img.height == height && width == 0;
        }
        valid ? resolve() : reject();
      };
      img.src = _URL.createObjectURL(file);
    })
    return isSize;
  },
  randomString(length) {
    var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var result = '';
    for (var i = length; i > 0; --i)
      result += str[Math.floor(Math.random() * str.length)];
    return result;
  }
}
export default utils