let apiBase = {
  login: '/api/admin/login',
  loginOut: '/api/admin/logout',
  queryPageList: '/api/admin/getUserPage',
  updateInfo: '/api/admin/editUser',
  addInfo: '/api/admin/addUser',
  deleteInfo: '/api/admin/deleteUser',
  getCurrentUser: '/api/admin/getCurUserDetail', // 获取用户信息
  getInfo: '/api/admin/getUserDetail', // 获取用户信息
  resetPassword: '/api/admin/resetPassword',  // 重置密码
  editPassword: '/api/admin/editPassword',  // 修改密码

}
let AccountSeverObject = null
class AccountSever {
  constructor() { }

  // 登录
  login(data = {}) {
    return $http({
      url: apiBase.login,
      method: 'post',
      data: data,
    })
  }

  // 登出
  loginOut(data = {}) {
    return $http({
      url: apiBase.loginOut,
      method: 'post',
      data: data,
    })
  }

  // 查询用户信息
  queryPageList(data = {}) {
    return $http({
      url: apiBase.queryPageList,
      method: 'post',
      data: data,
    })
  }

  updateInfo(data = {}) {
    return $http({
      url: apiBase.updateInfo,
      method: 'post',
      data: data,
    })
  }

  addInfo(data = {}) {
    return $http({
      url: apiBase.addInfo,
      method: 'post',
      data: data,
    })
  }

  deleteInfo(data = {}) {
    return $http({
      url: apiBase.deleteInfo,
      method: 'get',
      params: data,
    })
  }

  getCurrentInfo(data = {}) {
    return $http({
      url: apiBase.getCurrentUser,
      method: 'get',
      params: data,
    })
  }

  getInfo(data = {}) {
    return $http({
      url: apiBase.getInfo,
      method: 'get',
      params: data,
    })
  }

  resetPassword(data = {}) {
    return $http({
      url: apiBase.resetPassword,
      method: 'get',
      params: data,
    })
  }

  editPassword(data = {}) {
    return $http({
      url: apiBase.editPassword,
      method: 'post',
      data: data,
    })
  }

}
function singleAccountSever() {
  if (AccountSeverObject === null) {
    AccountSeverObject = new AccountSever()
  }
  return AccountSeverObject
}
export default singleAccountSever()