
// 1-保存未发布 2-预审中（审核中） 3-预审通过（审核中） 4-预审不通过（审核不通过） 14-厂商审核中（审核中） 15-已上架 16-审核不通过 17-已下架
//app审核状态 
export function appStateString(value) {
    return { '1': "保存未发布", '2': '预审中', '3': '预审通过', '4': '预审不通过','14': '厂商审核中', '15': '已上架', '16': '审核不通过', '17': '已下架' }['' + value] || value;
}


//app来源
export function appSourceString(value) {
    return { '1': "开发者上传", '2': '小米接口', '3': '联盟'}['' + value] || value;
}