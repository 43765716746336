import axios from 'axios'
import router from '@/router'
import {
  Message
} from 'element-ui'


let configBaseURL = document.location.protocol + process.env.VUE_APP_API //全局环境变量
//使用create方法创建axios实例
const Service = axios.create({
  timeout: 10000, // 请求超时时间
  baseURL: encodeURI(configBaseURL),
})
Service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
// 添加请求拦截器
Service.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('Token') ? localStorage.getItem('Token') : '';
  return config
}, error => {})
// 添加响应拦截器
Service.interceptors.response.use(response => {
  switch (response.data.code) {
    case 1104:
      localStorage.clear()
      // alert('登陆已过期');
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
      break;
    case 11012:
      localStorage.clear()
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
      break;
  }
  if (response.data['code'] == 0) {
    return response.data;
  } else {

    //优化同一个错误提示提示很多次
    let last = +window["error" + response.data.code];
    let now = +(new Date()).getTime();
    window["error" + response.data.code] = now;
    if (last && now - last < 500) {
      return response.data;
    }
    
    Message.error(response.data.msg);
    return response.data;
  }
}, error => {

  let errorMsg = error.message;
  if (error && error.response) {
    switch (error.response.status) {
      case 403:
        errorMsg = '禁止访问';
        break;
      case 404:
        errorMsg = '接口不存在';
        break;
      case 408:
        errorMsg = '请求超时';
        break;
      case 500:
        errorMsg = '服务器错误';
        break;
    }

    let last = +window["error" + error.response.status];
    let now = +(new Date()).getTime();
    window["error" + error.response.status] = now;
    if (last && now - last < 500) {
      return error;
    }
  }

  Message.error(errorMsg);
  return error
})
export default Service