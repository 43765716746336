import Vue from 'vue';
import Vuex from 'vuex';
import { singleAccountSever, roleServer } from "@/common/api-config";

Vue.use(Vuex);
const store = {
  namespaced: true,
  state: { //状态对象：共享值的集合
    "userInfo": {},//实际名
    "roleList": [],//角色数据
  },
  mutations: {  //同步修改
    set(state, obj) {//初始化
      for (let name in obj) {
        state[name] = obj[name];
      }
    },
    setUserInfo(state, obj) {
      state.userInfo = obj
    },
  },
  getters: {//监测状态
    getUserInfo: (state) => {
      return state.userInfo
    },
    getRoleList: (state) => {
      return state.roleList
    },

    //登录用户的操作权限
    getActionKeyList(state, obj) {
      return state.userInfo.actionKeyList
    },
    //登录用户的菜单权限
    getMenuKeyList(state, obj) {
      return state.userInfo.menuKeyList
    },

  },
  actions: {//异步修改
    updateRoleList(context) { //context 上下文对象，同管全局
      return new Promise((resolve, reject) => {
        // 实际上不会有很多role，100为了容错
        roleServer
          .selectorList({ pageNo: 1, pageSize: 100 })
          .then((res) => {
            if (res.code == 0 && res.data) {
              context.commit('set', {
                roleList: res.data,
              }); //上下文对象调用commit执行add方法
              resolve(res);
            } else {
              resolve({});
            }
          }).catch(e => {
            resolve({});
          })
      })
    },
  }
}

export default store

