import Vuex from 'vuex'
import * as modules from './modules'
import createPersistedState from 'vuex-persistedstate'
const store = new Vuex.Store({
	plugins: [createPersistedState({ storage: window.localStorage })],
	modules: {
		...modules
	}
});
export default store;
