
//新 只分预览和操作

export const menu_account = 'menu_account'
export const menu_account_develop_review = 'menu_account_develop_review'
// export const action_account_develop_review_list = 'action_account_develop_review_list'
// export const action_account_develop_review = 'action_account_develop_review'
export const action_account_develop_review_view = 'action_account_develop_review_view'
export const action_account_develop_review_operate = 'action_account_develop_review_operate'

export const menu_account_develop = 'menu_account_develop'
// export const action_account_develop_list = 'action_account_develop_list'
// export const action_account_develop_detail = 'action_account_develop_detail'
// export const action_account_develop_export = 'action_account_develop_export'
export const action_account_develop_view = 'action_account_develop_view'

export const menu_account_manager = 'menu_account_manager'
// export const action_account_manager_list = 'action_account_manager_list'
// export const action_account_manager_reset = 'action_account_manager_reset'
// export const action_account_manager_add = 'action_account_manager_add'
// export const action_account_manager_edit = 'action_account_manager_edit'
// export const action_account_manager_delete = 'action_account_manager_delete'
export const action_account_manager_view = 'action_account_manager_view'
export const action_account_manager_operate = 'action_account_manager_operate'

export const menu_account_role = 'menu_account_role'
// export const action_account_role_list = 'action_account_role_list'
// export const action_account_role_add = 'action_account_role_add'
// export const action_account_role_edit = 'action_account_role_edit'
// export const action_account_role_delete = 'action_account_role_delete'
export const action_account_role_view = 'action_account_role_view'
export const action_account_role_operate = 'action_account_role_operate'


export const menu_app = 'menu_app'
export const menu_app_review = 'menu_app_review'
// export const action_app_review_list = 'action_app_review_list'
// export const action_app_review_review = 'action_app_review_review'
export const action_app_review_view = 'action_app_review_view'
export const action_app_review_operate = 'action_app_review_operate'


export const menu_app_manager = 'menu_app_manager'
// export const action_app_manager_list = 'action_app_manager_list'
// export const action_app_manager_versionList = 'action_app_manager_versionList'
// export const action_app_manager_export = 'action_app_manager_export'
export const action_app_manager_view = 'action_app_manager_view'

export const menu_app_mi = 'menu_app_mi'
export const action_app_mi_view = 'action_app_mi_view'

// 1.4 新增权限
export const menu_app_filter = 'menu_app_filter'
export const action_app_filter_view = 'action_app_filter_view'
export const action_app_filter_operate = 'action_app_filter_operate'



export const menu_game = 'menu_game'
export const menu_game_review = 'menu_game_review'
// export const action_game_review_list = 'action_game_review_list'
// export const action_game_review_review = 'action_game_review_review'
export const action_game_review_view = 'action_game_review_view'
export const action_game_review_operate = 'action_game_review_operate'


export const menu_game_manager = 'menu_game_manager'
// export const action_game_manager_list = 'action_game_manager_list'
// export const action_game_manager_versionList = 'action_game_manager_versionList'
// export const action_game_manager_export = 'action_game_manager_export'
export const action_game_manager_view = 'action_game_manager_view'

export const menu_member = 'menu_member'
export const menu_member_info = 'menu_member_info'
// export const action_member_info_list = 'action_member_info_list'
// export const action_member_info_add = 'action_member_info_add'
// export const action_member_info_edit = 'action_member_info_edit'
export const action_member_info_view = 'action_member_info_view'
export const action_member_info_operate = 'action_member_info_operate'

export const menu_member_models = 'menu_member_models'
// export const action_member_models_list = 'action_member_models_list'
// export const action_member_models_add = 'action_member_models_add'
// export const action_member_models_edit = 'action_member_models_edit'
// export const action_member_models_delete = 'action_member_models_delete'
export const action_member_models_view = 'action_member_models_view'
export const action_member_models_operate = 'action_member_models_operate'

export const menu_apkversion = 'menu_apkversion'
export const menu_apkversion_store = 'menu_apkversion_store'
// export const action_apkversion_store_list = 'action_apkversion_store_list'
// export const action_apkversion_store_add = 'action_apkversion_store_add'
// export const action_apkversion_store_detail = 'action_apkversion_store_detail'
// export const action_apkversion_store_publish_page = 'action_apkversion_store_publish_page'
// export const action_apkversion_store_force_grade = 'action_apkversion_store_force_grade'
export const action_apkversion_store_view = 'action_apkversion_store_view'
export const action_apkversion_store_operate = 'action_apkversion_store_operate'


export const menu_apkversion_engine = 'menu_apkversion_engine'
// export const action_apkversion_engine_list = 'action_apkversion_engine_list'
// export const action_apkversion_engine_add = 'action_apkversion_engine_add'
// export const action_apkversion_engine_detail = 'action_apkversion_engine_detail'
// export const action_apkversion_engine_publish_page = 'action_apkversion_engine_publish_page'
// export const action_apkversion_engine_force_grade = 'action_apkversion_engine_force_grade'
export const action_apkversion_engine_view = 'action_apkversion_engine_view'
export const action_apkversion_engine_operate = 'action_apkversion_engine_operate'

export const menu_setting = 'menu_setting'
export const menu_setting_password = 'menu_setting_password'
// export const action_setting_password_modify = 'action_setting_password_modify'

export const menu_setting_api = 'menu_setting_api'
// export const action_setting_api_list = 'action_setting_api_list'

export const menu_setting_authkey = 'menu_setting_authkey'
// export const action_setting_authkey_add = 'action_setting_authkey_add'

//1.2
export const menu_setting_engine_version = 'menu_setting_engine_version'
export const action_setting_engine_version_view = 'action_setting_engine_version_view'
export const action_setting_engine_version_operate = 'action_setting_engine_version_operate'

export const menu_setting_device_statistics = 'menu_setting_device_statistics'

export const menu_setting_task = 'menu_setting_task'
export const action_setting_task_view = 'action_setting_task_view'
export const action_setting_task_operate = 'action_setting_task_operate'

//1.3
export const menu_setting_appstate = 'menu_setting_appstate'
//1.3新加权限
/*
[
    { "authorizedKey": "menu_setting_appstate", "name": "应用状态变化菜单", "type": 1 } 
]
*/

//1.4  新增运营管理
export const menu_operation = 'menu_operation'
export const menu_operation_news = 'menu_operation_news'
export const action_operation_news_view = 'action_operation_news_view'
export const action_operation_news_operate = 'action_operation_news_operate'
export const menu_operation_cooperation = 'menu_operation_cooperation'
export const action_operation_cooperation_view = 'action_operation_cooperation_view'
export const action_operation_cooperation_operate = 'action_operation_cooperation_operate'
