import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/util'
import * as filters from './filters'
import Service from './common/axios-config'
import ElementUI from 'element-ui'
import Vant from 'vant';
import 'vant/lib/index.css';
import * as authDirective from './directives/auth'
import * as AUTH_KEYS from './common/auth/auth-config'
// import {
//   singleAccountSever
// } from "@/common/api-config";


//1.针对vue配置
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant);
// 全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
//权限指令判断
Object.keys(authDirective).forEach(key => {
  Vue.directive(key, authDirective[key])
})

// // 2，添加全局工具
// // 根据网址确定请求接口地址
window.configBaseURL = Vue.prototype.configBaseURL = document.location.protocol + process.env.VUE_APP_API
window.utils = Vue.prototype.utils = utils
window.$http = Vue.prototype.$http = Service
window.AUTH_KEYS = Vue.prototype.AUTH_KEYS = AUTH_KEYS

//3,加载vue
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// //4，提前获取数据
// // 获取当前用户信息:用户基础信息与按钮权限
// const Token = localStorage.getItem('Token'); // 登录token
// if (Token) { //有token
//   singleAccountSever.getCurrentInfo().then((res) => {
//     if (res["code"] == 0) {
//       store.commit("accountStore/setUserInfo", res.data);
//     }
//   });

// }