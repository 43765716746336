import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
const store = {
  namespaced: true,
  state: { //状态对象：共享值的集合
    "gameList": [],//最近在玩数据
  },
  mutations: {  //同步修改
    set(state, obj) {//初始化
      for (let name in obj) {
        state[name] = obj[name];
      }
    },
    setGame(state, obj) {

      let tmplist = state.gameList;
      if (tmplist && tmplist.find(item => item.name == obj.name)) {
        return;
      }
      tmplist.unshift(obj);
      if (tmplist.length > 10) {
        tmplist.splice(0, 10);
      }
      state.gameList = tmplist;
    },
  },
  getters: {//监测状态
    getGameList: (state) => {
      return state.gameList
    }

  }
}

export default store

