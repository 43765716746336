const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),

  },
  {
    path: '/game',
    name: 'detail',
    component: () => import('@/views/Detail.vue'),

  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/List.vue'),

  }
]

export default routes;
